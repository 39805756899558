import { isAuthError } from '@supabase/supabase-js'

export default (
  loginType: 'request-otp' | 'password' | 'verify-otp',
  error: unknown
): string => {
  let errorMessage =
    "Well, that didn't work. Maybe try a different email address?"

  // When handling an AuthError, we can inspect the response to show a more accurate message in some cases.
  if (isAuthError(error)) {
    // Rate-limiting is possible regardless of login type
    if (error.status === 429) {
      errorMessage =
        "You've tried signing in too many times, please wait a minute to try again."
    }

    // Handle errors specific to each login type if necessary
    switch (loginType) {
      case 'request-otp':
        if (error.status === 422) {
          errorMessage =
            'Only confirmed accounts can log in with an email link.'
        }

        break

      case 'password':
        if (error.status === 400) {
          errorMessage = 'Invalid email or password.'
        }

        break

      case 'verify-otp':
        if (error.status === 403) {
          errorMessage = 'Invalid or incorrect login code.'
        }

        break
    }
  }

  return errorMessage
}
