<script setup lang="ts">
import type { FormSubmitEvent, TabItem } from '#ui/types'
import { z } from 'zod'

type LoginState =
  | { type: 'waiting' }
  | { type: 'loading' }
  | { type: 'success' }
  | { type: 'processing' }
  | { type: 'error'; error: string }

type OTPState = 'email' | 'code'

definePageMeta({ layout: 'auth' })
useHead({ title: 'Login' })

const tabItems: TabItem[] = [
  { slot: 'email-password', label: 'Password' },
  { slot: 'one-time-code', label: 'One Time Code' }
]

const user = useSupabaseUser()
const { sendOtp, signInWithPassword, verifyOtp } = useAuth()
const { checkTourCompletion } = useTours()

const requestOtpFormSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Your email address is required')
    .email('Invalid email address')
})

const passwordFormSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Your email address is required')
    .email('Invalid email address'),
  password: z.string().trim().min(1, 'Your password is required')
})

const verifyOtpFormSchema = z.object({
  code: z.string().trim().length(6, 'The login code must be exactly 6 digits')
})

type RequestOtpFormSchema = z.output<typeof requestOtpFormSchema>
type PasswordFormSchema = z.output<typeof passwordFormSchema>
type VerifyOtpFormSchema = z.output<typeof verifyOtpFormSchema>

const requestOtpState = reactive({ email: '' })
const passwordState = reactive({ email: '', password: '' })
const verifyOtpState = reactive({ code: '' })
const otpState = ref<OTPState>('email')
const requestOtpLoginState = ref<LoginState>({ type: 'waiting' })
const passwordLoginState = ref<LoginState>({ type: 'waiting' })
const verifyOtpLoginState = ref<LoginState>({ type: 'processing' })
const loading = computed(
  () =>
    requestOtpLoginState.value.type === 'loading' ||
    passwordLoginState.value.type === 'loading' ||
    verifyOtpLoginState.value.type === 'loading'
)

// Automatically redirect this window to the dashboard once the user is authenticated (the immediate flag takes care of redirecting on initial load)
watch(
  user,
  () => {
    if (user.value) {
      return navigateTo('/')
    }
  },
  { immediate: true }
)

const onSubmitEmailPass = async (
  event: FormSubmitEvent<PasswordFormSchema>
) => {
  passwordLoginState.value = { type: 'loading' }

  try {
    const { error } = await signInWithPassword(
      event.data.email,
      event.data.password
    )

    if (error) throw error

    passwordLoginState.value = { type: 'success' }

    await checkTourCompletion()
  } catch (error) {
    passwordLoginState.value = {
      type: 'error',
      error: getAuthErrorMessage('password', error)
    }
  }
}

const onErrorEmailPass = async () => {
  passwordLoginState.value = {
    type: 'error',
    error: 'Please check the form for issues and try again.'
  }
}

const onSubmitRequestOtp = async (
  event: FormSubmitEvent<RequestOtpFormSchema>
) => {
  requestOtpLoginState.value = { type: 'loading' }

  try {
    const { error } = await sendOtp(event.data.email)

    if (error) throw error

    requestOtpLoginState.value = { type: 'success' }
    otpState.value = 'code'
  } catch (error) {
    requestOtpLoginState.value = {
      type: 'error',
      error: getAuthErrorMessage('request-otp', error)
    }
  }
}

const onErrorRequestOtp = async () => {
  requestOtpLoginState.value = {
    type: 'error',
    error: 'Please check the form for issues and try again.'
  }
}

const onSubmitVerifyOtp = async (
  event: FormSubmitEvent<VerifyOtpFormSchema>
) => {
  verifyOtpLoginState.value = { type: 'loading' }

  try {
    const { error } = await verifyOtp(requestOtpState.email, event.data.code)

    if (error) throw error

    verifyOtpLoginState.value = { type: 'success' }

    await checkTourCompletion()
  } catch (error) {
    verifyOtpLoginState.value = {
      type: 'error',
      error: getAuthErrorMessage('verify-otp', error)
    }
  }
}

const onErrorVerifyOtp = async () => {
  requestOtpLoginState.value = {
    type: 'error',
    error: 'Please check the form for issues and try again.'
  }
}
</script>

<template>
  <UCard>
    <template #header>
      <div class="flex items-center justify-center gap-8">
        <CommonLogo />
      </div>
    </template>
    <UTabs :items="tabItems" class="w-full">
      <template #email-password>
        <UForm
          :schema="passwordFormSchema"
          :state="passwordState"
          class="space-y-4"
          @submit="onSubmitEmailPass"
          @error="onErrorEmailPass"
        >
          <UAlert
            color="orange"
            variant="outline"
            title="Don't have your password? Click on One Time Code!"
            description="From there you can use your email address to log in."
          />
          <UAlert
            v-if="passwordLoginState.type === 'error'"
            icon="i-heroicons-exclamation-circle"
            color="red"
            variant="outline"
            title="Error"
            :description="passwordLoginState.error"
          />
          <UAlert
            v-if="passwordLoginState.type === 'success'"
            icon="i-heroicons-check"
            color="green"
            variant="outline"
            title="Success"
            description="Hang tight, we are redirecting you to your dashboard."
          />
          <p>Enter your email and password to log into Retail Navigator</p>
          <UFormGroup label="Email" name="email">
            <UInput
              v-model="passwordState.email"
              type="email"
              autocomplete="email"
            />
          </UFormGroup>
          <UFormGroup label="Password" name="password">
            <UInput
              v-model="passwordState.password"
              type="password"
              autocomplete="current-password"
            />
          </UFormGroup>
          <UButton type="submit" :disabled="loading" :loading="loading">
            Login
          </UButton>
        </UForm>
      </template>
      <template #one-time-code>
        <UForm
          v-if="otpState === 'email'"
          :schema="requestOtpFormSchema"
          :state="requestOtpState"
          class="space-y-4"
          @submit="onSubmitRequestOtp"
          @error="onErrorRequestOtp"
        >
          <UAlert
            v-if="requestOtpLoginState.type === 'error'"
            icon="i-heroicons-exclamation-circle"
            color="red"
            variant="outline"
            title="Error"
            :description="requestOtpLoginState.error"
          />
          <p>
            Login with a one time use code by entering your email address. We
            will send you an email with your code to login with.
          </p>
          <UFormGroup label="Email" name="email">
            <UInput
              v-model="requestOtpState.email"
              type="email"
              autocomplete="email"
            />
          </UFormGroup>
          <UButton type="submit" :disabled="loading" :loading="loading">
            Send Code
          </UButton>
        </UForm>
        <UForm
          v-else-if="otpState === 'code'"
          :schema="verifyOtpFormSchema"
          :state="verifyOtpState"
          class="space-y-4"
          @submit="onSubmitVerifyOtp"
          @error="onErrorVerifyOtp"
        >
          <UAlert
            v-if="verifyOtpLoginState.type === 'error'"
            icon="i-heroicons-exclamation-circle"
            color="red"
            variant="outline"
            title="Error"
            :description="verifyOtpLoginState.error"
          />
          <UAlert
            v-if="['error', 'processing'].includes(verifyOtpLoginState.type)"
            icon="i-heroicons-information-circle"
            color="primary"
            variant="outline"
            title="Login Code Sent"
            description="Check your email for your login code and enter it below."
          />
          <UAlert
            v-if="verifyOtpLoginState.type === 'success'"
            icon="i-heroicons-check"
            color="green"
            variant="outline"
            title="Success"
            description="Hang tight, we are redirecting you to your dashboard."
          />
          <UFormGroup label="Login Code" name="code">
            <UInput
              v-model="verifyOtpState.code"
              type="text"
              inputmode="decimal"
              autocomplete="off"
            />
          </UFormGroup>
          <UButton type="submit" :disabled="loading" :loading="loading">
            Verify Code
          </UButton>
        </UForm>
      </template>
    </UTabs>
  </UCard>
</template>
